import i20 from "./CarPic/i20.jpg";
import Audi_A4 from "./CarPic/Audi_A4.jpg";
import Baleno from "./CarPic/Baleno.jpg";
import BMW from "./CarPic/BMW-5-Series.jpg";
import Brezza from "./CarPic/Brezza.jpg";
import Carens from "./CarPic/Carens.jpg";
import Creta from "./CarPic/Creta.jpg";
import Crysta from "./CarPic/Crysta.jpg";
import Endeavour from "./CarPic/Endeavour.jpg";
import ErtigaHybrid from "./CarPic/ErtigaHybrid.jpg";
import Fortuner from "./CarPic/Fortuner.jpg";
import Hycross from "./CarPic/Hycross.jpg";
import Swift from "./CarPic/Swift.jpg";
import Thar from "./CarPic/Thar.jpg";
import Venue from "./CarPic/Venue.jpg";
import Fronx from "./CarPic/Fronx.jpg"

export const CarData = [
    {
        img:i20,
        carName:"i20",
        transmissionType: "Manual",
        sitterNumber:"5",
        milage:"20 km/L",
        luggageNumber : "5 Luggage",
        fuleType: "Petrol",
        fuleCapacity: "37 Ltr",
        value:"1500",
    },
    {
        img:i20,
        carName:"i20",
        transmissionType: "Automatic",
        sitterNumber:"5",
        milage:"20 km/L",
        luggageNumber : "5 Luggage",
        fuleType: "Petrol",
        fuleCapacity: "37 Ltr",
        value:"2500",
    },
    {
      img:Creta,
      carName:"Hyundai Creata (Manual)",
      transmissionType: "Manual",
      sitterNumber:"5",
      milage:"19 km/L",
      luggageNumber : "5 Luggage",
      fuleType: "Petrol",
      fuleCapacity: "55 Ltr",
      value:"2500",
    },
    {
        img:Creta,
        carName:"Hyundai Creata",
        transmissionType: "Automatic Panoramic Sunroof",
        sitterNumber:"5",
        milage:"19 km/L",
        luggageNumber : "5 Luggage",
        fuleType: "Petrol",
        fuleCapacity: "55 Ltr",
        value:"3500",
     },
    {
      img:Swift,
      carName:"Swift",
      transmissionType: "Manual",
      sitterNumber:"5",
      milage:"16 km/L",
      luggageNumber : "3 Luggage",
      fuleType: "Petrol",
      fuleCapacity: "37 Ltr",
      value:"1200",
    },
    {
        img:Swift,
        carName:"Swift",
        transmissionType: "Automatic",
        sitterNumber:"5",
        milage:"16 km/L",
        luggageNumber : "3 Luggage",
        fuleType: "Petrol",
        fuleCapacity: "37 Ltr",
        value:"1600",
    },
    {
      img:Baleno,
      carName:"Belano",
      transmissionType: "Manual",
      sitterNumber:"5",
      milage:"18 km/L",
      luggageNumber : "3 Luggage",
      fuleType: "Petrol",
      fuleCapacity: "40 Ltr",
      value:"1200",
    },
    {
        img:Baleno,
        carName:"Belano",
        transmissionType: "Manual",
        sitterNumber:"5",
        milage:"18 km/L",
        luggageNumber : "3 Luggage",
        fuleType: "Petrol",
        fuleCapacity: "40 Ltr",
        value:"1600",
    },
    {
      img:ErtigaHybrid,
      carName:"Maruti Suzuki Ertiga",
      transmissionType: "Manual",
      sitterNumber:"7",
      milage:"19 km/L",
      luggageNumber : "5 Luggage",
      fuleType: "Petrol",
      fuleCapacity: "45 Ltr",
      value:"2200",
    },
    {
        img:ErtigaHybrid,
        carName:"Maruti Suzuki Ertiga",
        transmissionType: "Automatic",
        sitterNumber:"7",
        milage:"19 km/L",
        luggageNumber : "5 Luggage",
        fuleType: "Petrol",
        fuleCapacity: "45 Ltr",
        value:"2800",
      },
    {
      img:Thar,
      carName:"Mahindra Thar",
      transmissionType: "Manual",
      sitterNumber:"6",
      milage:"12 km/L",
      luggageNumber : "3 Luggage",
      fuleType: "Petrol",
      fuleCapacity: "50 Ltr",
      value:"3000",
    },
    {
        img:Thar,
        carName:"Mahindra Thar",
        transmissionType: "Automatic",
        sitterNumber:"6",
        milage:"12 km/L",
        luggageNumber : "3 Luggage",
        fuleType: "Petrol",
        fuleCapacity: "50 Ltr",
        value:"3500",
    },
    {
        img:Brezza,
        carName:"Brezza",
        transmissionType: "Manual",
        sitterNumber:"5",
        milage:"17 km/L",
        luggageNumber : "3 Luggage",
        fuleType: "Petrol",
        fuleCapacity: "48 Ltr",
        value:"2500",
    },
    {
        img:Brezza,
        carName:"Brezza",
        transmissionType: "Automatic",
        sitterNumber:"5",
        milage:"17 km/L",
        luggageNumber : "3 Luggage",
        fuleType: "Petrol",
        fuleCapacity: "48 Ltr",
        value:"3000",
    },
    {
        img:Fronx,
        carName:"Fronx",
        transmissionType: "Manual",
        sitterNumber:"5",
        milage:"20 km/L",
        luggageNumber : "3 Luggage",
        fuleType: "Petrol",
        fuleCapacity: "37 Ltr",
        value:"1200",
    },
    {
        img:Fronx,
        carName:"Fronx",
        transmissionType: "Automatic",
        sitterNumber:"5",
        milage:"20 km/L",
        luggageNumber : "3 Luggage",
        fuleType: "Petrol",
        fuleCapacity: "37 Ltr",
        value:"1600",
    },
    {
        img:Venue,
        carName:"Venue",
        transmissionType: "Manual",
        sitterNumber:"7",
        milage:"17 km/L",
        luggageNumber : "5 Luggage",
        fuleType: "Petrol",
        fuleCapacity: "45 Ltr",
        value:"2500",
    },
    {
        img:Venue,
        carName:"Venue",
        transmissionType: "Automatic",
        sitterNumber:"7",
        milage:"17 km/L",
        luggageNumber : "3 Luggage",
        fuleType: "Petrol",
        fuleCapacity: "45 Ltr",
        value:"3000",
    },
    {
        img:Crysta,
        carName:"Crysta",
        transmissionType: "Manual",
        sitterNumber:"7",
        milage:"12 km/L",
        luggageNumber : "5 Luggage",
        fuleType: "Petrol",
        fuleCapacity: "65 Ltr",
        value:"3000",
    },
    {
        img:Crysta,
        carName:"Crysta",
        transmissionType: "Automatic",
        sitterNumber:"7",
        milage:"8 km/L",
        luggageNumber : "5 Luggage",
        fuleType: "Petrol",
        fuleCapacity: "65 Ltr",
        value:"3800",
    },
    {
        img:ErtigaHybrid,
        carName:"ErtigaHybrid",
        transmissionType: "Manual",
        sitterNumber:"7",
        milage:"21 km/L",
        luggageNumber : "5 Luggage",
        fuleType: "Petrol",
        fuleCapacity: "45 Ltr",
        value:"2200",
    },
    {
        img:ErtigaHybrid,
        carName:"ErtigaHybrid",
        transmissionType: "Automatic",
        sitterNumber:"7",
        milage:"21 km/L",
        luggageNumber : "5 Luggage",
        fuleType: "Petrol",
        fuleCapacity: "45 Ltr",
        value:"2800",
    },
    {
        img:Carens,
        carName:"Carens",
        transmissionType: "Manual",
        sitterNumber:"7",
        milage:"16 km/L",
        luggageNumber : "5 Luggage",
        fuleType: "Petrol",
        fuleCapacity: "45 Ltr",
        value:"2200",
    },
    {
        img:Carens,
        carName:"Carens",
        transmissionType: "Automatic",
        sitterNumber:"7",
        milage:"16 km/L",
        luggageNumber : "5 Luggage",
        fuleType: "Petrol",
        fuleCapacity: "45 Ltr",
        value:"2800",
    },
    {
        img:Fortuner,
        carName:"Fortuner",
        transmissionType: "Automatic",
        sitterNumber:"7",
        milage:"10 km/L",
        luggageNumber : "5 Luggage",
        fuleType: "Petrol",
        fuleCapacity: "80 Ltr",
        value:"7000",
    },
    {
        img:Endeavour,
        carName:"Endeavour",
        transmissionType: "Automatic",
        sitterNumber:"7",
        milage:"12 km/L",
        luggageNumber : "5 Luggage",
        fuleType: "Petrol",
        fuleCapacity: "80 Ltr",
        value:"6000",
    },
    {
        img:BMW,
        carName:"BMW 5 Series",
        transmissionType: "Automatic",
        sitterNumber:"5",
        milage:"16 km/L",
        luggageNumber : "5 Luggage",
        fuleType: "Petrol",
        fuleCapacity: "60 Ltr",
        value:"15000",
    },
    {
        img:Audi_A4,
        carName:"Audi A4",
        transmissionType: "Automatic",
        sitterNumber:"5",
        milage:"14.8 km/L",
        luggageNumber : "4 Luggage",
        fuleType: "Petrol",
        fuleCapacity: "54 Ltr",
        value:"15000",
    },
  ]