import React from "react";
import AirportImage from "../../../../Images/Airport.jpg"
import { Avatar, Button, Card } from 'antd';

const { Meta } = Card;

const Airport = props => {  
    return (
      <div>  
              <Card
              style={{
                width: '100%',
                margin: '20px',
                textAlign: 'center'
              }}
            >
              <Meta
                title={<h1>Can We Rent A Car From Goa Airport?</h1>}
                description={<></>}
              />
              <img src={AirportImage} style={{ width: '100%' }}/>
              <p style={{ maxWidth: '100%', marginBottom: '20px' }}>
              Yes, you can indeed Rent A Car From Goa Airport. Be it Dabolim Airport (GOI) Or Manohar Airport (GOX), various car rental agencies, including our own, Car Rental Service, offer a convenient and hassle-free way for travelers to access transportation as soon as they land in this coastal paradise. Car Rental at Goa Airport provides several advantages. It grants you the freedom to explore Goa's breathtaking landscapes, pristine beaches, and vibrant culture at your own pace. The airport is a key entry point for tourists, making it the ideal location to pick up a rental car and begin your adventure without delay.
              </p>
              <p style={{ maxWidth: '100%', marginBottom: '20px' }}>
              Most car rental companies offer a diverse fleet of vehicles, ranging from compact cars for solo travelers to spacious SUVs for families and larger groups. You can choose a vehicle that suits your specific travel needs. To Rent A Self Drive Car Rental In Goa Airport, you'll just have to connect with us & pre-book the car you want to hire in Goa. Once you've arrived & completed the necessary paperwork, you'll be on your way to exploring the wonders of Goa.
              </p>
              <p style={{ maxWidth: '100%', marginBottom: '20px' }}>
              Airport Self Drive Car Rental not only provides convenience but also opens up a world of possibilities for your journey, allowing you to experience the beauty of Goa on your own terms. So, whether you're arriving for a vacation or a business trip, renting a car at Goa Airport is a smart choice to kickstart your Goan adventure.
              </p>
            </Card>

      </div> 
    );
  }
  
export default Airport;
  