import React, {useState} from "react";
import { EditOutlined, EllipsisOutlined, SettingOutlined } from '@ant-design/icons';
import { Avatar, Button, Card, Modal, Row, Col } from 'antd';
import ReservePage from "../Reserve/Reserve";
import { FxemojiFuelpump } from "../../Icon/FxemojiFuelpump";
import { IcBaselinePerson2 } from "../../Icon/IcBaselinePerson2";
import { IconParkOutlineManualGear } from "../../Icon/IconParkOutlineManualGear";
import { LineMdSpeedLoop } from "../../Icon/LineMdSpeedLoop";
import { CarData } from "../../Data/CarData";

const { Meta } = Card;



const Cars = props => {
  const [reserveDetails, setReserveDetails] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
      setIsModalOpen(true);
    };
    
    const handleCancel = () => {
      setIsModalOpen(false);
    };

    const ReserveClick = (details) => {
      setReserveDetails(details);
      showModal();
    }


    return (
      <div style={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
      }}>
        {CarData.map((items,i)=>{
            return(
              <Card
              style={{
                width: 400,
                margin: '20px'
              }}
              cover={
                <img
                  alt="Car Image"
                  src={items.img}
                />
              }
              actions={[
                <Button onClick={() => ReserveClick(items)}>Reserve Now</Button>
              ]}
            >
              <Meta
                title={items.carName}
                description={<p>₹ <span style={{color:'green', fontWeight: 'bold', fontSize: '1.2em' }}>{items.value}</span>/day</p>}
              />
              <Row>
                <Col span={6} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <IconParkOutlineManualGear />
                  <div>{items.transmissionType}</div>
                </Col>
                <Col span={6} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <IcBaselinePerson2 />
                  <div>{items.sitterNumber}</div>
                </Col>
                <Col span={6} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <FxemojiFuelpump />
                  <div>{items.fuleCapacity}</div>
                </Col>
                <Col span={6} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <LineMdSpeedLoop />
                  <div>{items.milage}</div>
                </Col>
              </Row>
            </Card>
            )
          })}
          <Modal open={isModalOpen} footer={null} onCancel={handleCancel}>
              <ReservePage details={reserveDetails}/>
          </Modal> 
      </div>
    );
  }
  
export default Cars;
  