import React from 'react';
import { IcBaselineCall } from './IcBaselineCall';

function CallLink() {
  const phoneNumber = '+918623848422'; // Replace with your phone number including the country code

  return (
    <a 
      href={`tel:${phoneNumber}`} 
      style={{ textDecoration: 'none', color: 'blue' }}
    >
      <IcBaselineCall/>
    </a>
  );
}

export default CallLink;
