import React from "react";
import CarRentalService from "../../Images/SKCarRental.png"
import { Avatar, Button, Card } from 'antd';

const { Meta } = Card;

const About = props => {  
    return (
      <div>  
              <Card
              style={{
                width: '100%',
                margin: '20px',
                textAlign: 'center'
              }}
            >
              <Meta
                title={<h1>About Us</h1>}
                description={<></>}
              />
              <img src={CarRentalService} style={{ width: '100%' }}/>
              <p style={{ maxWidth: '100%', marginBottom: '20px' }}>
                We at Car Rental Service, the best Goa car rental service provider in the state of Goa, are committed to providing high quality self-driving cars in Goa. We are on a mission to give travelers the privilege of creating their own flexible itinerary to explore the magical state of Goa. We're here to simplify the process of choosing the perfect trip to explore and create memories in Goa. Every traveler has different budgets, needs, and preferences. To meet diverse needs, we provide customers with many options to choose from. Our rental cars collection includes compact sedans, midsize sedans, and SUVs that are well-maintained, insured, and GPS-equipped. For us, customer service comes first. We pride ourselves on providing excellent customer service to ensure customers have a seamless car rental experience. So sit back and relax, we will prepare your next trip to Goa with our rental cars! All you need to do is take a simple step by contacting our team, and we will provide you with the best car rental solutions in Goa for you! Pack your travel bags and enjoy the tropical beauty at your leisure!
              </p>
            </Card>
            <Card
              style={{
                width: '100%',
                margin: '20px',
                textAlign: 'center'
              }}
            >
              <Meta
                title={<h1>Terms & Condition</h1>}
                description={<></>}
              />  
              <ul>
                <li>Driver Must Be a Minimum of 18 Years Old to Hire a Car</li>
                <li>One Valid Document is Required - Aadhar Card, PAN Card, Voter ID Card</li>
                <li>Security Deposit is Mandatory</li>
                <li>Take Photograph or Video of Rented Car</li>
                <li>The Driver is Liable to Pay Any Damages Done</li>
                <li>Car Should be Returned on the Same Fuel</li>
                <li>Morning 9AM To Next 9AM Morning Is Considered As 1 Day</li>
              </ul>
            </Card>
            <Card
              style={{
                width: '100%',
                margin: '20px',
                textAlign: 'center'
              }}
            >
              <Meta
                title={<h1>Refund Policy</h1>}
                description={<></>}
              />  
              <ul>
                <li>Request Refund 24 Hours Prior to Trip Start</li>
                <li>20% Amount Would be Charged as a Cancellation Fee</li>
              </ul>
            </Card>
      </div> 
    );
  }
  
export default About;
  