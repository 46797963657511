import React, { useState } from 'react';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from '@ant-design/icons';
import { Button, Layout, Menu, theme } from 'antd';
import {Outlet, Link} from "react-router-dom"; //Outlet points out where the children routes should be placed
import {
    HomeOutlined,
    CarOutlined,
    InfoCircleOutlined
} from '@ant-design/icons';
import FooterContent from '../../component/Footer/Footer';
import WhatsAppLink from '../../component/WhatsApp/WhatsAppLink';
import CallLink from '../../component/CallButton/CallLink';
import SKCarRental from '../../Images/SKCarRental.png';

const { Header, Footer, Sider, Content } = Layout;

const RootLayout = props => {
    const [collapsed, setCollapsed] = useState(true);
    const {
      token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();
    return (
      <Layout>
        <Sider trigger={null} collapsible collapsed={collapsed}>
          <div className="demo-logo-vertical" />
          <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={['1']}
          >
          <Menu.Item key="1" icon={<HomeOutlined />}>
            <Link to="">Home</Link>
          </Menu.Item>
          <Menu.Item key="2" icon={<CarOutlined />}>
            <Link to="carlist">Cars</Link>
          </Menu.Item>
          <Menu.Item key="3" icon={<InfoCircleOutlined />}>
            <Link to="AboutUs">About</Link>
          </Menu.Item>
          <Menu.Item key="3">
            <WhatsAppLink/>
          </Menu.Item>
        </Menu>
      </Sider>
        <Layout>
        <Header
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between', // Ensure space between items
              padding: 0,
              background: '#fff', // use the appropriate variable or value for colorBgContainer
            }}
          >
            <Button
              type="text"
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => setCollapsed(!collapsed)}
              style={{
                fontSize: '16px',
                width: 64,
                height: 64,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            />
            <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
              <img src={SKCarRental} style={{ width: '5%' }} alt="Car Rental Service" />
            </div>
            <CallLink/>
            <div style={{ width: 64 }} /> {/* Placeholder to balance the flex space */}
          </Header>

          <Content
            style={{
              margin: '24px 16px',
              padding: 24,
              minHeight: 280,
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
            }}
          >
            <Outlet/>
          </Content>
          
          <Footer style={{
            textAlign: 'center',
            color: '#fff',
            backgroundColor: '#4096ff',
          }}><FooterContent/></Footer>
        </Layout>
      </Layout>
    );
};

export default RootLayout