import React, { useState } from "react";
import { Card, Col, Row } from 'antd';
import {
    Button,
    Cascader,
    DatePicker,
    Form,
    Input,
    InputNumber,
    Mentions,
    Select,
    TreeSelect,
} from 'antd';
import { IconParkOutlineManualGear } from "../../Icon/IconParkOutlineManualGear";
import { IcBaselinePerson2 } from "../../Icon/IcBaselinePerson2";
import { FxemojiFuelpump } from "../../Icon/FxemojiFuelpump";
import { LineMdSpeedLoop } from "../../Icon/LineMdSpeedLoop";
import EmailLink from "../../component/EmailLink/EmailLink";

const contentStyle = {
  height: '160px',
  color: '#fff',
  lineHeight: '160px',
  textAlign: 'center',
  background: '#364d79',
};

const { Meta } = Card;
const { RangePicker } = DatePicker;
const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 6,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 14,
      },
    },
  };


const ReservePage = props => {
  const [showMessage, setShowMessage] = useState(false);
  const [emailTemplate, setEmailTemplate] = useState({});

  const onFinish = async (values,details) => {
    let emailAddress= "skcarrentals41@gmail.com"
    let subject= `Reservation Request for ${details.carName}`
    let body= `Dear Support Team,

I would like to reserve the ${details.carName} from ${values.DatePicker[0].format('YYYY-MM-DD HH:mm')} to ${values.DatePicker[1].format('YYYY-MM-DD HH:mm')}.
Pickup Location: ${values.PickupLocation}
Name: ${values.Name}
Phone Number: ${values.PhoneNumber}
Email: ${values.Email}

Please confirm the availability.

Thank you,
${values.Name}`
    setShowMessage(true)
    let emailData = {
      emailAddress:emailAddress,
      subject:subject,
      body:body,
    }
    setEmailTemplate(emailData);
    try {
      const response = await fetch('https://us-central1-skcarrentalbackend.cloudfunctions.net/app/sendmail', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(emailData),
      });

      const data = await response.json();
      if (response.ok) {
          console.log('Email sent:', data);
      } else {
          console.error('Failed to send email:', data);
      }
  } catch (error) {
      console.error('Error:', error);
  }
    return;
  };
  
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

    return(
        <>
            <Card
              style={{
                width: '90%',
                margin: '10px',
                height: '300px', // Reduce the height of the card
                marginBottom: '150px', // Add space below this card
              }}
              cover={
                <img
                  alt="Car Image"
                  src={props.details.img}
                />
              }
            //   actions={[
            //     <img src=''/>
            //   ]}
            >
            <Meta
                title={props.details.carName}
                description={<p>₹ <span style={{color:'green', fontWeight: 'bold', fontSize: '1.2em' }}>{props.details.value}</span>/day</p>}
            />
            <Row>
                <Col span={6} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <IconParkOutlineManualGear />
                  <div>{props.details.transmissionType}</div>
                </Col>
                <Col span={6} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <IcBaselinePerson2 />
                  <div>{props.details.sitterNumber}</div>
                </Col>
                <Col span={6} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <FxemojiFuelpump />
                  <div>{props.details.fuleCapacity}</div>
                </Col>
                <Col span={6} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <LineMdSpeedLoop />
                  <div>{props.details.milage}</div>
                </Col>
              </Row>
            </Card>
            {!showMessage? <Form
                {...formItemLayout}
                variant="filled"
                style={{
                    maxWidth: 600,
                    margin: '50px auto',
                }}
                initialValues={{
                  remember: true,
                }}
                onFinish={(values) => onFinish(values, props.details)}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Form.Item
                  label="Name"
                  name="Name"
                  rules={[
                    {
                      required: true,
                      message: 'Please input!',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              
                <Form.Item
                  label="Phone Number"
                  name="PhoneNumber"
                  rules={[
                    {
                      required: true,
                      message: 'Please input!',
                    },
                  ]}
                >
                  <InputNumber
                    style={{
                      width: '100%',
                    }}
                  />
                </Form.Item>
                
                <Form.Item
                  label="Email"
                  name="Email"
                  rules={[
                    {
                      required: true,
                      message: 'Please input!',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Pickup Location"
                  name="PickupLocation"
                  rules={[
                    {
                      required: true,
                      message: 'Please input!',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Drop Location"
                  name="DropLocation"
                  rules={[
                    {
                      required: true,
                      message: 'Please input!',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              
                {/*<Form.Item
                  label="Car Picked"
                  name="CarPicked"
                  rules={[
                    {
                      required: true,
                      message: 'Please input!',
                    },
                  ]}
                >
                  <Select />
                </Form.Item> */}
              
                <Form.Item
                  label="DatePicker"
                  name="DatePicker"
                  rules={[
                    {
                      required: true,
                      message: 'Please input!',
                    },
                  ]}
                >
                    <RangePicker 
                      showTime={{ format: 'HH:mm' }} // This enables time selection with a custom time format
                      format="YYYY-MM-DD HH:mm" // The overall format for date and time
                    />
                </Form.Item>
              
                <Form.Item
                  wrapperCol={{
                    offset: 6,
                    span: 16,
                  }}
                >
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Form>:<Button>Mail Sent Successfully</Button>}
        </>
    )
}

export default ReservePage;