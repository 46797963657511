import React from "react";
import classes from './Home.module.css'
import Carousel from "../../component/Carousle/Carousle";
import Carlist from "./Carlist/Carlist";
import Service from "./Service/Service";
import Destination from "./Destination/Destination";
import CallForm from "./CallForm/CallForm";
import Footer from "../../component/Footer/Footer";

const Home = props => {
    return (<>
        <div className={classes.Home}>
            <div className={classes.carousel}><Carousel/></div>
            <div style={{marginTop:'30px'}}><Carlist/></div>
            <div style={{marginTop:'30px'}}><Destination/></div>
            <div style={{marginTop:'30px'}}><Service/></div>
            <div style={{marginTop:'30px'}}><CallForm/></div>
        </div>
      </>
    );
  }
  
export default Home;
  