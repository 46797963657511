import React from 'react';
import Carousel from 'react-material-ui-carousel';
import Car1 from '../../Images/hyundai-white-creta-car.jpg';
import Car2 from '../../Images/Thar.jpeg';
import Car3 from '../../Images/roadtrip-1583526313-1.jpg';

import classes from './carousle.module.css';

const Carouselfun = props => {
    var items = [
        {
            name: "Car1",
            img: Car1
        },
        {
            name: "Car2",
            img: Car2
        },
        {
            name: "Car3",
            img: Car3
        },
    ];

    const carouselStyle = {
        position: 'relative',
        width: '100%',
        height: 'auto'
    };

    const imgStyle = {
        position: 'relative',
        width: '100%',
        height: '100%'
    };

    const imgSizeStyle = {
        width: '100%',
        height: 'auto'
    };

    const textOverlayStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        color: 'white',
        textAlign: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Optional: Add a background to make text more readable
        padding: '10px',
        borderRadius: '5px'
    };

    return (
        <div>
            <Carousel stopAutoPlayOnHover={false} style={carouselStyle}>
                {
                    items.map((item, i) => (
                        <div key={i} style={imgStyle}>
                            <div style={textOverlayStyle}>
                                <h1>Rent.Ride.Rejoice</h1>
                                <p>Really in the mood for a long drive with no real destination</p>
                            </div>
                            <img style={imgSizeStyle} src={item.img} alt={item.name} />
                        </div>
                    ))
                }
            </Carousel>
        </div>
    );
}

export default Carouselfun;
