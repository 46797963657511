function EmailLink(emailAddress,subject,body) {
    console.log("emailAddress: ", emailAddress)
    console.log("subject: ", subject);
    console.log("body: ", body)
  return (
    <a 
      href={`mailto:${emailAddress}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`} 
      style={{ textDecoration: 'none', color: 'red' }}
    />
  );
}

export default EmailLink;
