import React from "react";
import { Card } from 'antd';

const Service = props => {

    return <>
    <Card title="Travel Guides">
        <Card type="inner" title="Can We Rent A Car From Goa Airport?" extra={<a href="AirportPage">More</a>}>
            Yes, you can indeed Rent A Car From Goa Airport...
        </Card>
        <Card
          style={{
            marginTop: 16,
          }}
          type="inner"
          title="What Is The Deposit For Self Drive Car Rental In Goa?"
          extra={<a href="DepositPage">More</a>}
        >
          The security deposit is a refundable amount anywhere...
        </Card>
        <Card
          style={{
            marginTop: 16,
          }}
          type="inner"
          title="What Documents Are Required To Rent A Car In Goa?"
          extra={<a href="DocumentPage">More</a>}
        >
          Alright! Let's say you are planning to rent a self-drive...
        </Card>
    </Card>
    </>
}

export default Service;
