import React from "react";
import DepositImage from "../../../../Images/Deposit.jpg"
import { Avatar, Button, Card } from 'antd';

const { Meta } = Card;

const DepositPage = props => {  
    return (
      <div>  
              <Card
              style={{
                width: '100%',
                margin: '20px',
                textAlign: 'center'
              }}
            >
              <Meta
                title={<h1>What Is The Deposit For Self Drive Car Rental In Goa?</h1>}
                description={<></>}
              />
              <img src={DepositImage} style={{ width: '100%' }}/>
              <p style={{ maxWidth: '100%', marginBottom: '20px' }}>
              The security deposit is a refundable amount anywhere between 2000/- To 5000/- depends on the car you choose to hire. It's taken as a safeguard by the rental supplier in case of any damage. This amount will be fully refunded if you return the vehicle without any damage during the rental period. To avoid any scratches or damage, make sure to only drive on the road and do not deviate. Apart from this Cars for Rent in Goa start from 900 INR per day and go up to 4,000 INR or 5,000 INR per day depending upon the type of car you choose as per your budget.
              </p>
              <p style={{ maxWidth: '100%', marginBottom: '20px' }}>
              Luxury Cars are quite expensive, the flip side budget-friendly options available are WagonR, i10, or Celerio. Prices are a bit high on the weekend depending on the demands of cars. To avoid legal issues, make sure you have a valid Indian Driving License or International Driving Permit (IDP). Additionally, it is important to familiarize yourself with Indian driving laws and guidelines to minimize the risk of accidents. If you have a driver's license, consider yourself safe. Second, before renting a car, you should check the condition of the car.              </p>
              <p style={{ maxWidth: '100%', marginBottom: '20px' }}>
              Check the vehicle's condition, look for wear and tear and, if necessary, take photos and videos as evidence. Finally, for Rent A Car Goa, choose only reputable car rental companies in Goa.              </p>
            </Card>

      </div> 
    );
  }
  
export default DepositPage;
   