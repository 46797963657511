import React from "react";
import DocumentImage from "../../../../Images/Documnet.jpg"
import { Avatar, Button, Card } from 'antd';

const { Meta } = Card;

const DepositPage = props => {  
    return (
      <div>  
              <Card
              style={{
                width: '100%',
                margin: '20px',
                textAlign: 'center'
              }}
            >
              <Meta
                title={<h1>What Is The Deposit For Self Drive Car Rental In Goa?</h1>}
                description={<></>}
              />
              <img src={DocumentImage} style={{ width: '100%' }}/>
              <p style={{ maxWidth: '100%', marginBottom: '20px' }}>
              Alright! Let's say you are planning to rent a self-drive car in Goa. The question is whether you are new to renting a car or are you experienced. Considering you're in the middle of the trip, renting a car can be nerve-wracking for you. If you want to Hire A Self Drive Car Rental In Goa, you must have a valid driver's license. You must also present your original identity proof such as Aadhaar card, voter ID which will be returned to you upon return of the vehicle.
              </p>
              <p style={{ maxWidth: '100%', marginBottom: '20px' }}>
              The most popular options for car rental in Goa are self-drive cars. Here you can create your own itinerary and explore Goa in your own way. The word self-driving car is quite easy to understand. Essentially, a self-driving car is a car that you can drive yourself without hiring a driver. With self-driving cars, tourists can eliminate the hassle of using public transport and explore Goa's lush natural paradise, including coastal areas and sites like the Waterfalls Dudhsagar and Salim Ali Bird Sanctuary.
              </p>              
              <p style={{ maxWidth: '100%', marginBottom: '20px' }}>
              Our cars are well-maintained and regularly serviced because your safety and comfort matter the most to us! We work day in and out to provide our customers with the best in the game experience when Renting A Car in Goa.
              </p>
            </Card>

      </div> 
    );
  }
  
export default DepositPage;
   