import React from "react";
import { Link } from "react-router-dom";
import { Card, Col, Row } from 'antd';
import { Carousel } from 'antd';
import {Button } from 'antd';

import Agonda from '../../../Images/Destination/Agonda.jpg';
import Butterfly from '../../../Images/Destination/Butterfly.jpg';
import Calangute from '../../../Images/Destination/Calangute.jpg';
import Dudhsagar from '../../../Images/Destination/Dudhsagar.jpg';
import Palolem from '../../../Images/Destination/Palolem.jpg';


const contentStyle = {
  height: '160px',
  color: '#fff',
  lineHeight: '160px',
  textAlign: 'center',
  background: '#364d79',
};

const { Meta } = Card;

const Destination = props => {
    const list = [
        {
        img:Agonda,
        carName:"Agonda Beach",
        value:"2800",
      },
      {
        img:Butterfly,
        carName:"Butterfly Beach",
        value:"1200",
      },
      {
        img:Calangute,
        carName:"Calangute Beach",
        value:"1200",
      },
      {
        img:Dudhsagar,
        carName:"Dudhsagar Beach",
        value:"1500",
      },
      {
        img:Palolem,
        carName:"Palolem Beach",
        value:"1500",
      },
    ]

    const carouselSettings = {
      autoplay: true,
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3, // Show 3 cards at a time
      slidesToScroll: 3, // Scroll 3 cards at a time
      responsive: [
        {
          breakpoint: 768, // When screen width is less than 768px
          settings: {
            slidesToShow: 1, // Show 1 card
            slidesToScroll: 1, // Scroll 1 card
          },
        },
      ],
    }

    return(
        <div>  
            <h1 style={{  position: 'relative', left:'40%',width: '28%'}}> Popular <span style={{    color: 'red', position: 'relative'}}>Destination</span></h1>
            <Carousel {...carouselSettings}>
            {list.map((items,i)=>{
            return(
              <Card
              style={{
                width: '90%',
                margin: '10px',
                height: '300px' // Reduce the height of the card
              }}
              cover={
                <img
                  alt="Image"
                  src={items.img}
                />
              }
            >
              <Meta
                title={items.carName}
                description={<p>₹ <span style={{color:'green', fontWeight: 'bold', fontSize: '1.2em' }}>{items.value}</span></p>}
              />
            </Card>
            )})}
            </Carousel>
        </div>
    )
}

export default Destination;