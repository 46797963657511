import React  from 'react';
import './App.css';
import { createBrowserRouter,RouterProvider } from 'react-router-dom';
import Home from './Pages/Home/Home';
import Cars from './Pages/Cars/Cars';
import ContactUs from './Pages/ContactUs/ContactUs';
import About from './Pages/AboutUs/About';
import TermsCondition from './Pages/TermsCondition/TermsCondition';
import PrivacyPolicy from './Pages/PrivacyPolicy/PrivacyPolicy';
import RootLayout from './Pages/RootLayout/RootLayout';
import AirportPage from '../src/Pages/Home/Service/ServicePage/AirportPage.js';
import DepositPage from './Pages/Home/Service/ServicePage/DepositPage.js';
import DocumentPage from './Pages/Home/Service/ServicePage/DocumentPage.js';

const router = createBrowserRouter([
  {
    path:"/",
    element:<RootLayout/>,
    children:[
      {
        path:"/",
        element:<Home/>,
        // loader: loader,
      },
      {
        path:"carlist",
        element: <Cars/>,
        //loader: Carloader,
      },
      // {
      //   path:"Contact",
      //   element:<ContactUs/>,
      //   //Loader: ContactLoader,
      // },
      {
        path:"AboutUS",
        element:<About/>,
        //Loader: AboutLoader,
      },
      {
        path:"TermsandCondition",
        element:<TermsCondition/>,
      },
      {
        path:"PrivacyPolicy",
        element:<PrivacyPolicy/>
      },
      {
        path:"AirportPage",
        element:<AirportPage/>
      },
      {
        path:"DepositPage",
        element:<DepositPage/>
      },
      {
        path:"DocumentPage",
        element:<DocumentPage/>
      },
      {
        path:"*",
        element:<Home/>
      }
    ]
  } 
  
]);


const App = props => {
  return (
      <RouterProvider router={router}/>
  );
}

export default App;
