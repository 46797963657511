import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Card, Button, Carousel, Modal, Col, Row } from 'antd';
import ReservePage from "../../Reserve/Reserve";
import { IconParkOutlineManualGear } from "../../../Icon/IconParkOutlineManualGear";
import { IcBaselinePerson2 } from "../../../Icon/IcBaselinePerson2";
import { FxemojiFuelpump } from "../../../Icon/FxemojiFuelpump";
import { LineMdSpeedLoop } from "../../../Icon/LineMdSpeedLoop";
import { CarData } from "../../../Data/CarData";

import classes from './Carlist.module.css';


const { Meta } = Card;

const Carlist = props => {
    const [reserveDetails, setReserveDetails] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);

    // const list = [
    //     {
    //     img:Hyundai,
    //     carName:"Hyundai Creta (Manual)",
    //     transmissionType: "Manual",
    //     sitterNumber:"5 seater",
    //     milage:"19 km/L",
    //     luggageNumber : "5 Luggage",
    //     fuleType: "Petrol",
    //     fuleCapacity: "55 Ltr",
    //     value:"2800",
    //   },
    //   {
    //     img:Swift,
    //     carName:"Swift (Manual)",
    //     transmissionType: "Manual",
    //     sitterNumber:"5 seater",
    //     milage:"16 km/L",
    //     luggageNumber : "3 Luggage",
    //     fuleType: "Petrol",
    //     fuleCapacity: "37 Ltr",
    //     value:"1200",
    //   },
    //   {
    //     img:Baleno,
    //     carName:"Baleno (Manual)",
    //     transmissionType: "Manual",
    //     sitterNumber:"5 seater",
    //     milage:"18 km/L",
    //     luggageNumber : "3 Luggage",
    //     fuleType: "Petrol",
    //     fuleCapacity: "40 Ltr",
    //     value:"1200",
    //   },
    //   {
    //     img:Swift,
    //     carName:"Swift (Automatic)",
    //     transmissionType: "Automatic",
    //     sitterNumber:"5 seater",
    //     milage:"16 km/L",
    //     luggageNumber : "3 Luggage",
    //     fuleType: "Petrol",
    //     fuleCapacity: "37 Ltr",
    //     value:"1500",
    //   },
    //   {
    //     img:Baleno,
    //     carName:"Baleno (Automatic)",
    //     transmissionType: "Automatic",
    //     sitterNumber:"5 seater",
    //     milage:"18 km/L",
    //     luggageNumber : "3 Luggage",
    //     fuleType: "Petrol",
    //     fuleCapacity: "40 Ltr",
    //     value:"1500",
    //   },
    // ]

    const showModal = () => {
      setIsModalOpen(true);
    };
    
    const handleCancel = () => {
      setIsModalOpen(false);
    };

    const carouselSettings = {
      autoplay: true,
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3, // Show 3 cards at a time
      slidesToScroll: 3, // Scroll 3 cards at a time
      responsive: [
        {
          breakpoint: 768, // When screen width is less than 768px
          settings: {
            slidesToShow: 1, // Show 1 card
            slidesToScroll: 1, // Scroll 1 card
          },
        },
      ],
    }

    const ReserveClick = (details) => {
      setReserveDetails(details);
      showModal();
    }

    return(
      <>
          <div>  
            <h1 className={classes.head}>High <span className={classes.span}>Demanded</span> Cars</h1>
            <Carousel {...carouselSettings}>
            {CarData.map((items,i) => (
              <Card
              key={i}
              style={{
                width: '90%',
                margin: '10px',
                height: '300px' // Reduce the height of the card
              }}
              cover={
                <img
                  alt="Car Image"
                  src={items.img}
                />
              }
              actions={[
                <Button onClick={() => ReserveClick(items)}>Reserve Now</Button>
              ]}
            >
              <Meta
                title={items.carName}
                description={<p>₹ <span style={{color:'green', fontWeight: 'bold', fontSize: '1.2em' }}>{items.value}</span>/day</p>}
              />
              <h5 style={{color: 'red'}}>Minimum Two Days Advance Booking</h5>
              <Row>
                <Col span={6} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <IconParkOutlineManualGear />
                  <div>{items.transmissionType}</div>
                </Col>
                <Col span={6} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <IcBaselinePerson2 />
                  <div>{items.sitterNumber}</div>
                </Col>
                <Col span={6} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <FxemojiFuelpump />
                  <div>{items.fuleCapacity}</div>
                </Col>
                <Col span={6} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <LineMdSpeedLoop />
                  <div>{items.milage}</div>
                </Col>
              </Row>
            </Card>
            ))}
            </Carousel>
            <div style={{  
              display: 'flex',
              justifyContent: 'center',
              marginTop: '20px'
            }}>
              <Link to="carlist">
                <Button style={{
                    padding: '10px 20px',
                    borderRadius: '5px',
                    cursor: 'pointer',
                }}>View More</Button>
              </Link>
            </div>
          </div>
            <Modal open={isModalOpen} footer={null} onCancel={handleCancel}>
              <ReservePage details={reserveDetails}/>
          </Modal>  
      </>
    )
}

export default Carlist;
