import React from "react";
import classes from './PrivacyPolicy.module.css';
import Footer from "../../component/Footer/Footer";

const PrivacyPolicy = props => {
    return(<>
        <div className={classes.container}>
            <h1 className={classes.head}>Privacy Policy</h1>
            <p>
            This privacy policy has been compiled to better serve those who are concerned with how their ‘Personally Identifiable Information’ (PII) is being used online. 
            PII, as described in India privacy law and information security, is information that can be used on its own or with other information to identify, contact, 
            or locate a single person, or to identify an individual in context. Please read our privacy policy carefully to get a clear understanding of how we collect,
            use, protect or otherwise handle your Personally Identifiable Information in accordance with our website.
            </p>
            <h2>What personal information do we collect from the people that visit our blog, website or app</h2>
            <p>When ordering or registering on our site, as appropriate, you may be asked to enter your name, email address, mailing address, phone number or other details to help you with your experience.</p>
            <h2>When do we collect information?</h2>
            <p>We collect information from you when you register on our site, place an order, fill out a form or enter information on our site.</p>
            <h2>How do we use your information?</h2>
            <p>We may use the information we collect from you when you register, make a purchase, sign up for our newsletter, respond to a survey or marketing communication, surf the website, or use certain other site features in the following ways:</p>
            <ul>
                <li>To personalize your experience and to allow us to deliver the type of content and product offerings in which you are most interested.</li>
                <li>To improve our website in order to better serve you.</li>
                <li>To allow us to better service you in responding to your customer service requests.</li>
                <li>To administer a contest, promotion, survey or other site feature.</li>
                <li>To quickly process your transactions.</li>
                <li>To ask for ratings and reviews of services or products</li>
                <li>To ask for ratings and reviews of services or products</li>
            </ul>
            <h2>How do we protect your information?</h2>
            <ul>
                <li>Our website is scanned on a regular basis for security holes and known vulnerabilities in order to make your visit to our site as safe as possible.</li>
                <li>We use secure http prototocol otherwise known as HTTPS and SSL certificate to keep the data encrypted between the browser and the server.</li>
            </ul>
            <h2>Do we use ‘cookies’?</h2>
            <p>We use cookies. Cookies are small files that a site or its service provider transfers to your computer’s hard drive through your Web browser (if you allow) that enables the site’s or service provider’s systems to recognize your browser and capture and remember certain information. For instance, we use cookies to help us remember and process the items in your shopping cart. They are also used to help us understand your preferences based on previous or current site activity, which enables us to provide you with improved services. We also use cookies to help us compile aggregate data about site traffic and site interaction so that we can offer better site experiences and tools in the future.</p>
            <h2>Third-party disclosure</h2>
            <p>We do not sell, trade, or otherwise transfer to outside parties your Personally Identifiable Information unless we provide users with advance notice. This does not include website hosting partners and other parties who assist us in operating our website, conducting our business, or serving our users, so long as those parties agree to keep this information confidential. We may also release information when it’s release is appropriate to comply with the law, enforce our site policies, or protect ours or others’ rights, property or safety. However, non-personally identifiable visitor information may be provided to other parties for marketing, advertising, or other uses.</p>
            <h2>Third-party links</h2>
            <p>Occasionally, at our discretion, we may include or offer third-party products or services on our website. These third-party sites have separate and independent privacy policies. We therefore have no responsibility or liability for the content and activities of these linked sites. Nonetheless, we seek to protect the integrity of our site and welcome any feedback about these sites. If at any time you would like to unsubscribe from receiving future emails, you can email us at and we will promptly remove you from ALL correspondence.</p>
        </div>
        <div className={classes.footer}>
            <Footer/>
        </div>
    </>)
}

export default  PrivacyPolicy;