import React, {useState} from 'react';
import { Button, Checkbox, Form, Input } from 'antd';
import EmailLink from '../../../component/EmailLink/EmailLink';

const CallForm = () => {
  const [showMessage, setShowMessage] = useState(false)
  const [emailTemplate, setEmailTemplate] = useState({})

  const onFinish = async (values) => {
    let emailAddress = "skcarrentals41@gmail.com";
    let subject = `Call Request from ${values.name}`;
    let body = `Name: ${values.name}\n
    Phone Number: ${values.Phone}\n\n
    Please contact this person to schedule a call.`;

    setShowMessage(true)
    let emailData = {
      emailAddress:emailAddress,
      subject:subject,
      body:body,
    }
    setEmailTemplate(emailData);

    try {
      const response = await fetch('https://us-central1-skcarrentalbackend.cloudfunctions.net/app/sendmail', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(emailData),
      });

      const data = await response.json();
      if (response.ok) {
          console.log('Email sent:', data);
      } else {
          console.error('Failed to send email:', data);
      }
  } catch (error) {
      console.error('Error:', error);
  }

    return;
  };
  
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  return<>
  <div style={{ textAlign: 'center' }}> 
    <h1>Hop on a call</h1>
    {!showMessage?<Form
      name="basic"
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 16,
      }}
      style={{
        maxWidth: 600,
        margin: '0 auto' // Center the form
      }}
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        label="Name"
        name="name"
        rules={[
          {
            required: true,
            message: 'Enter Your Name',
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Phone Number"
        name="Phone"
        rules={[
          {
            required: true,
            message: 'Enter Your Phone Number',
          },
        ]}
      >
        <Input/>
      </Form.Item>

      <Form.Item
        wrapperCol={{
          offset: 8,
          span: 16,
        }}
      >
        <Button type="primary" htmlType="submit" >
          Submit
        </Button>
      </Form.Item>
    </Form>:<a 
                href={`mailto:${emailTemplate.emailAddress}?subject=${encodeURIComponent(emailTemplate.subject)}&body=${encodeURIComponent(emailTemplate.body)}`} 
                style={{ textDecoration: 'none', color: 'red' }}
              >Mail Sent</a>}
  </div>
  </>
};

export default CallForm;
