import React from 'react';
import { LogosWhatsappIcon } from './LogosWhatsappIcon';

function WhatsAppLink() {
  const phoneNumber = '+918623848422'; // Replace with your WhatsApp number including the country code

  return (
    <a 
      href={`https://wa.me/${phoneNumber}`} 
      target="_blank" 
      rel="noopener noreferrer"
      style={{ textDecoration: 'none', color: 'green' }}
    >
      <LogosWhatsappIcon/>
    </a>
  );
}

export default WhatsAppLink;
